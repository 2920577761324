<template>
  <div class="request-list">
    <div class="d-flex align-center mb-2">
      <div class="text-sm-h5 text-h6 font-weight-bold">Offer feature requests</div>
      <v-spacer></v-spacer>
      <v-text-field
        class="field48"
        v-model="search"
        placeholder="Search by user name or full name"
        hide-details
        background-color="white"
        :append-icon="search ? 'mdi-close' : ''"
        @click:append="clearSearch"
        outlined
        dense
        required
        color="primary"
        height="48"
      ></v-text-field>
      <v-btn @click="(page = 1), getData()" class="primary px-0 rounded ms-2" icon depressed width="48" height="48">
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn>
    </div>
    <div class="d-flex align-center mb-3">
      <v-select
        v-model="status"
        :items="itemsStatus"
        @change="(page = 1), getData()"
        background-color="white"
        style="max-width: 194px"
        append-icon="mdi-chevron-down"
        hide-details
        item-text="title"
        item-value="name"
        outlined
        dense
        required
        height="40"
        color="primary"
      ></v-select>
      <v-btn @click="(page = 1), (status = 'all'), getData()" text class="ms-3 px-2" v-if="status != 'all'">
        <v-icon color="primary">mdi-close</v-icon>
        <span class="text-600 text-body-1 primary--text">Clear</span>
      </v-btn>
    </div>
    <v-data-table
      class="d-md-block d-none"
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="data.results"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:no-data v-if="!data.pages">
        <div class="d-flex flex-column align-center text-center my-15">
          <div style="max-width: 370px" class="f18 gray--text font-weight-medium my-6">No requests added yet</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="black--text link" style="height: 56px" @click="clickItem(item.id)">
            <td>{{ new Date(item.updated_at).toLocaleString('en-US', options) }}</td>
            <td>{{ item.person_full_name }}</td>
            <td>@{{ item.person_username }}</td>
            <td>{{ item.car_name }}</td>
            <td>{{ item.car_plate_number }}</td>
            <td>
              <div class="status" :class="statusColor(item.status)">
                {{ item.status == 'submitted' ? 'new' : item.status }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer>
        <v-divider v-if="data.pages > 1"></v-divider>
        <div class="mt-2 pb-2 px-3 d-flex align-center" v-if="data.pages > 1">
          <v-spacer></v-spacer>
          <v-pagination
            color="primary"
            prev-icon="mdi-arrow-left-thin"
            next-icon="mdi-arrow-right-thin"
            @input="getData"
            v-model="page"
            :length="data.pages"
            :total-visible="5"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
    <div class="d-md-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.pages">
        <div style="max-width: 370px" class="f18 gray--text font-weight-medium my-6">No accounts added yet</div>
      </div>
      <div v-else v-for="item in data.results" :key="item.id" class="link">
        <div class="d-flex align-center f15">
          <div>Submitted at</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.updated_at }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Full name</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.person_full_name }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>User</div>
          <v-spacer></v-spacer>
          <div class="gray--text">@{{ item.person_username }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Car</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.car_name }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Plate number</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.car_plate_number }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Status</div>
          <v-spacer></v-spacer>
          <div class="status" :class="statusColor(item.status)">
            {{ item.status == 'submitted' ? 'new' : item.status }}
          </div>
        </div>
      </div>
      <div class="mt-2 pb-2 mx-auto" v-if="data.pages > 1">
        <v-pagination
          color="primary"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getData"
          v-model="page"
          :length="data.pages"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      status: this.$route.query.status || 'all',
      search: this.$route.query.src || '',
      page: +this.$route.query.page || 1,
      headers: [
        { text: 'Submitted at', value: 'updated_at', sortable: false },
        { text: 'Full name', value: 'person_full_name', sortable: false },
        { text: 'User', value: 'username', sortable: false },
        { text: 'Car', value: 'car_name', sortable: false },
        { text: 'Plate number', value: 'car_plate_number', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
      ],
      itemsStatus: [
        { name: 'all', title: 'All' },
        { name: 'submitted', title: 'Submitted' },
        { name: 'approved', title: 'Approved' },
        { name: 'rejected', title: 'Rejected' },
      ],
      options: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      statusError: '',
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Error',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Error',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clickItem(id) {
      this.$router.push(`/offer-requests/${id}`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    statusColor(s) {
      if (s == 'submitted') {
        return 'warning';
      } else if (s == 'rejected') {
        return 'error';
      } else {
        return 'success';
      }
    },
    async getData() {
      this.statusError = '';
      this.error = [];
      await this.$store
        .dispatch('getRequestList', {
          search: this.search,
          page: this.page,
          status: this.status,
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error.find((i) => i == 'page__out_of_bounds')) {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.requestList;
    },
  },
  destroyed() {
    this.$store.dispatch('setRequestList');
  },
};
</script>

<style lang="scss">
.request-list {
  .field48 {
    max-width: 400px;
    width: 100%;
    .v-input__append-inner {
      margin-top: 14px !important;
    }
  }
  .status {
    width: max-content;
    padding: 4px 12px;
    color: white;
    text-transform: capitalize;
    font-size: 15px;
    border-radius: 4px;
  }
}
</style>
